<template>
  <b-navbar
    sticky
    justified
    fill
    style="background-color: #10163a; height: 6rem; width: 100%"
  >
    <b-navbar-brand align="start">
      <!-- <font-awesome-icon v-if="companyImage === 'default' " icon="building" size="6x" class="mt-10" :style="{ color: 'white' }" /> -->
      <router-link :to="{name: 'home'}">
        <img
          :src="latestCompanyImage"
          style="height: 7rem"
          class="w-auto object-scale-down"
        >
      </router-link>
    </b-navbar-brand>
    <b-navbar-nav
      class="ml-auto"
      align="end"
    >
      <div
        v-if="isLoggedIn"
        class="w-100"
      >
        <b-button
          to="/"
          class="w-100 shadow-lg"
          variant="primary"
        >{{
          $store.state.auth.ActiveUser.account_type === this.$AccountTypes.APPLICANT
            ? 'Manage My Application'
            : 'Dashboard'
        }}</b-button>
      </div>

      <div
        v-else
        class="w-100"
      >
        <b-button
          class="w-full shadow-lg"
          variant="primary"
          @click="activateLogin"
        >Sign In</b-button>
      </div>
    </b-navbar-nav>

    <router-view
      @image-update="updateCompanyImage"
      @login-state="updateUserLoggedIn"
    />
    <b-modal
      v-model="login_form"
      centered
      size="lg"
      hide-footer
      title=""
    >
      <b-row cols="12">
        <h6 class="mb-6 ml-1">
          You will require a HeadHunters HQ account to proceed with the
          application.
        </h6>
      </b-row>

      <b-row cols="12">
        <b-tabs
          v-model="active_tab"
          align="center"
          class="w-100"
          fill
        >
          <b-tab
            title="Login"
            class="w-100"
          >
            <LoginForm
              class="w-100"
              which-page="listings"
              :event-id="this.$router.currentRoute.path"
              @user-logged-in="afterUserLoggedIn"
            />
          </b-tab>
          <b-tab
            title="Registration"
            class="w-100"
          >
            <RegistrationForm
              class="w-100"
              which-page="listings"
              account-type="Applicant"
              @user-registered="afterUserLoggedIn"
            />
          </b-tab>
        </b-tabs>
      </b-row>
    </b-modal>
  </b-navbar>
</template>

<script>
import {
  BButton,
  BNavbar,
  BModal,
  BNavbarNav,
  BNavbarBrand,
  BTabs,
  BTab,
  BRow,
} from 'bootstrap-vue'

import { isUserLoggedIn } from '@/auth/utils'

import LoginForm from '@/views/authentication/login/LoginForm.vue'
import RegistrationForm from '@/views/authentication/registration/RegistrationForm.vue'

export default {
  components: {
    LoginForm,
    RegistrationForm,

    BNavbarNav,
    BButton,
    BModal,
    BNavbar,
    BNavbarBrand,
    BTabs,
    BTab,
    BRow,
  },
  props: {
    companyImage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isLoggedIn: false,
      login_form: false,
      active_tab: 0,
      footer_class: 'w-full',
      latestCompanyImage: 'not-set',
      special_styling: '',
      accountancy_event: false,
      isUserLoggedIn,
    }
  },
  watch: {
    companyImage(newLink) {
      // this.latestCompanyImage = newLink
      this.$generateDownloadUrl(newLink)
        .then(res => {
          this.latestCompanyImage = res
        })
    },
    '$store.state.auth.ActiveUser': function (newVal) {
      this.isLoggedIn = true
    },
  },

  async created() {
    this.isLoggedIn = isUserLoggedIn()

    this.latestCompanyImage = this.companyImage
    if (
      this.$router.currentRoute.path === '/listings'
    ) {
      this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
    } else if (this.$route.name === 'company-listings-page') {
      this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
    }
    let { event_id } = this.$route.params
    if (
      this.$route.params.company
      && this.$route.params.company.endsWith('_g')
    ) {
      event_id = this.$route.params.company.replace('_g', '')
      if (event_id === '61dcd0dc62adac05c99714c4') {
        this.accountancy_event = true
      }
    } else if (event_id === '61dcd0dc62adac05c99714c4') {
      this.accountancy_event = true
    }
  },
  methods: {

    afterUserLoggedIn() {
      this.isLoggedIn = true
      this.login_form = false
    },
    async updateCompanyImage(image) {
      if (image === 'default') {
        this.latestCompanyImage = await this.$generateDownloadUrl('media/hq_logo.png')
      } else {
        this.latestCompanyImage = image
      }
    },
    activateLogin() {
      if (isUserLoggedIn()) {
        this.isLoggedIn = true
        this.login_form = false
      } else {
        this.login_form = true
      }
    },

    updateUserLoggedIn() {
      this.isLoggedIn = isUserLoggedIn()
    },
  },
}
</script>
<style>
#app > div > header {
  background-color: #10163a;
}
</style>
