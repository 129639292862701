<template>
  <div style="height: 100vh;">
    <iframe
      :src="file_source"
      width="100%"
      height="100%"
      frameborder="0"
    />
  </div>
</template>

<script>

export default {
  data() {
    return {
      file_source: '',
    }
  },
  created() {
    const baseURL = process.env.VUE_APP_BASE_URL
    const fileURL = `${baseURL}/api/media/privacy-policy.docx`
    // https://view.officeapps.live.com/op/embed.aspx?src=
    this.file_source = `https://view.officeapps.live.com/op/embed.aspx?src=${fileURL}`
  },
}
</script>
